.find-prm-tutorial {
  padding: 70px 10px;
}

.find-prm-tutorial .inner {
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Arial,
    sans-serif;
  margin: auto;
  max-width: 550px;
  padding: 25px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.find-prm-tutorial h1 {
  color: black;
  font-size: 30px;
  text-align: center;
  margin-bottom: 40px;
  line-height: 38px;
}

.find-prm-tutorial h2 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 11px;
  color: #373737;
  font-weight: 600;
}

.find-prm-tutorial p,
.find-prm-tutorial ol {
  color: #666;
  line-height: 1.6;
  margin-top: 10px;
  margin-bottom: 10px;
}

.find-prm-tutorial li {
  margin-top: 0px;
  margin-bottom: 0px;
}

.find-prm-tutorial ol {
  padding-left: 20px;
}
