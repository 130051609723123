.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app {
  position: relative; /* Relative positioning for the app container */
  height: 100vh; /* Set the height to cover the entire viewport */
  overflow-y: auto; /* Allow the app content to scroll */
}

.background-image {
  position: fixed; /* Fix the background position */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1; /* Place the background behind other content */
}
