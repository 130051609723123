.find-tuya-deviceid-tutorial {
  padding: 70px 10px;
}

.find-tuya-deviceid-tutorial .inner {
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Arial,
    sans-serif;
  margin: auto;
  max-width: 550px;
  padding: 25px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.find-tuya-deviceid-tutorial h1 {
  color: black;
  font-size: 30px;
  text-align: center;
  margin-bottom: 40px;
  line-height: 38px;
}

.find-tuya-deviceid-tutorial h2 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 11px;
  color: #373737;
  font-weight: 600;
}

.find-tuya-deviceid-tutorial img {
  width: 100%;
  max-width: 320px;
  margin: 17px auto 48px auto;
  display: block;
  border-radius: 7px;
  filter: brightness(95%); /* Add constrast between img and background */
}
