body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a.link_tutorial {
  text-decoration: none;
}

a.link_tutorial:hover {
  text-decoration: underline;
}

/* Hides "powered by Google" in address dropdown */
.pac-container:after {
  background-image: none !important;
  height: 0px;
}

.autocomplete-container {
  width: 100%;
  font-size: 20px;
}

.autocomplete-container .autocomplete-input {
  width: 100%;
  font-size: 16px;
  padding: 17px 11px;
  border-radius: 5px;
  border-style: solid;
}

.autocomplete-noerror {
  border-radius: 5px;
  border-width: 1px;
  border-color: #c4c4c3;
  border-style: solid;
  outline-color: #002160;
}

.autocomplete-error {
  border-radius: 5px;
  border-width: 1px;
  border-color: red;
  border-style: solid;
  outline-color: #d3302f;
}

.autocomplete-submitting {
  border-radius: 5px;
  border-width: 1px;
  border-color: #bdbdbc;
  border-style: solid;
  color: #bdbdbc;
  background-color: #fffffe;
}

.autocomplete-noerror::placeholder {
  color: #666666;
}

.autocomplete-error::placeholder {
  color: #d3302f;
}

.autocomplete-noerror:hover {
  border-color: #000000;
}
